import React, { useEffect, useState } from "react";
import { ethers } from 'ethers';
import { contractAbi, contractAddress } from './../Constant/constant';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import "./connected.css";

const Connected = (props) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [whitelistAddress, setWhitelistAddress] = useState('');
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [candidates, setCandidates] = useState([]);


    useEffect(() => {
        props.getRemainingTime();
        fetchUserRole();
        checkWhitelisted();
        fetchRemainingTime();
        fetchCandidates(); // Add this line to fetch candidates

        toast.info('Welcome to the voting app!');

        const interval = setInterval(() => {
            setRemainingTime(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const fetchCandidates = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            const candidatesList = await contractInstance.getAllVotesOfCandidates();
            const formattedCandidates = candidatesList.map((candidate, index) => {
                return {
                    index: index,
                    name: candidate.name,
                    voteCount: candidate.voteCount.toNumber()
                }
            });
            setCandidates(formattedCandidates);
            console.log(setCandidates, "the candidates");
        } catch (error) {
            console.error('Error fetching candidates:', error);
        }
    };
    
    


    const fetchRemainingTime = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contractInstance = new ethers.Contract(
                contractAddress,
                contractAbi,
                provider
            );
            const endTimestamp = await contractInstance.votingEnd();
            const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
            const remainingTime = Math.max(0, endTimestamp - currentTime);
            setRemainingTime(remainingTime);
        } catch (error) {
            console.error('Error fetching remaining time:', error);
        }
    };
    

    const checkWhitelisted = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            const canVote = await contractInstance.canVote(await signer.getAddress());
            setIsWhitelisted(canVote);
        } catch (error) {
            console.error('Error checking whitelisted status:', error);
        }
    };

    const handleAddCandidate = async () => {
        try {
            
            if (!isWhitelisted) {
                toast.error('You are not whitelisted to vote');
                return;
            }

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            const tx = await contractInstance.addCandidate(props.candidateInput);
            await tx.wait();
            
            const updatedCandidatesList = await contractInstance.getAllVotesOfCandidates();
            const formattedCandidates = updatedCandidatesList.map((candidate, index) => {
                return {
                    index: index,
                    name: candidate.name,
                    voteCount: candidate.voteCount.toNumber()
                }
            });
            props.setCandidates(formattedCandidates);
            toast.success('Candidate added successfully');
            
            props.setCandidateInput('');
        } catch (error) {
            console.error('Error adding candidate:', error);
            toast.error('Failed to add candidate');
        }
    };

    const canVote = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            const voteStatus = await contractInstance.voters(await signer.getAddress());
            props.setCanVote(!voteStatus);
        } catch (error) {
            console.error('Error checking if user can vote:', error);
        }
    };

    const handleVote = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            const tx = await contractInstance.vote(props.number);
            await tx.wait();
            
            props.getCandidates();
            
            canVote();
            toast.success('Vote successful');
        } catch (error) {
            console.error('Error voting:', error);
            toast.error('Failed to vote');
        }
    };

    const handleWhitelistCreator = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            await contractInstance.whitelistCampaignCreator(whitelistAddress);
            toast.success('Address whitelisted as campaign creator');
        } catch (error) {
            console.error('Error whitelisting campaign creator:', error);
            toast.error('Failed to whitelist address as campaign creator');
        }
    };

    const handleWhitelistVoter = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            await contractInstance.whitelistVoter(whitelistAddress);
            toast.success('Address whitelisted as voter');
        } catch (error) {
            console.error('Error whitelisting voter:', error);
            toast.error('Failed to whitelist address as voter');
        }
    };

    const handleCheckCanVote = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            const canVote = await contractInstance.getVotingStatus();
            if (canVote) {
                toast.info('You can vote');
            } else {
                toast.info('You cannot vote');
            }
        } catch (error) {
            console.error('Error checking if user can vote:', error);
            toast.error('Failed to check if user can vote');
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} Minutes ${remainingSeconds} seconds`;
    };

    const fetchUserRole = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const contractInstance = new ethers.Contract (
                contractAddress, contractAbi, signer
            );
            const isAdmin = await contractInstance.isAdmin(await signer.getAddress());
            const isCampaignCreator = await contractInstance.campaignCreatorWhitelist(await signer.getAddress());
            if (isAdmin) {
                setUserRole('admin');
            } else if (isCampaignCreator) {
                setUserRole('campaignCreator');
            } else {
                setUserRole('regularVoter');
            }
        } catch (error) {
            console.error('Error fetching user role:', error);
        }
    };


    return (
        <Container className="container-fluid">
            <Container fluid>
                <h1 className="connected-header">Hi, You are Connected to Metamask</h1>
                <Row>
                    <Col>
                        <p className="connected-account text-bold" style={{ fontWeight: "bold" }}>Metamask Account: {props.account.slice(0, 8)}...{props.account.slice(-6)}</p>
                    </Col>
                    <Col>
                        <p className="connected-account" style={{ fontWeight: "bold" }}>Voting Ends in {formatTime(remainingTime)}</p>
                    </Col>
                </Row>
                
                {userRole === 'admin' && (
                    <>
                    <Row>
                        <Col className="col-col">
                            <Form>
                                <Form.Group controlId="candidateInput">
                                    <Form.Label>Enter candidate names separated by commas</Form.Label>
                                    <Form.Control type="text" value={props.candidateInput} onChange={props.handleCandidateInputChange} />
                                </Form.Group>
                                <Form.Group controlId="duration">
                                    <Form.Label>Duration in minutes</Form.Label>
                                    <Form.Control type="number" value={props.duration} onChange={props.handleDurationChange} />
                                </Form.Group>
                                <Button className="btn-primary" onClick={props.handleCreateCampaign}>Create Campaign</Button>
                                <Button onClick={handleAddCandidate}>Add Candidate</Button>
                            </Form>
                        </Col>
                    </Row>
               

                <Row>
                    <Col className="col-col">
                        {props.showButton ? (
                            <p className="connected-account">You have already voted</p>
                        ) : (
                            <Form>
                                <Form.Group controlId="candidateIndex">
                                    <Form.Label>Enter Candidate Index</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Candidate Index" value={props.number} onChange={props.handleNumberChange} />
                                </Form.Group>
                                {isWhitelisted ? (
                                        <Button style={{textAlign:"center"}} className="login-button" onClick={handleVote}>Vote</Button>
                                    ) : (
                                        <Button style={{textAlign:"center"}} className="login-button" disabled onClick={() => toast.error('You are not whitelisted to vote')}>Vote</Button>
                                    )}
                            </Form>
                        )}
                    </Col>
                    <Col className="col-col">
                            <Form>
                                <Form.Group controlId="whitelistCreator">
                                    <Form.Label>Whitelist Campaign Creator</Form.Label>
                                    <Form.Control type="text" value={whitelistAddress} onChange={(e) => setWhitelistAddress(e.target.value)} />
                                </Form.Group>
                                <Button onClick={handleWhitelistCreator}>Whitelist Creator</Button>
                            </Form>
                    </Col>
                    <Col className="col-col">
                            <Form>
                                <Form.Group controlId="whitelistVoter">
                                    <Form.Label>Whitelist Voter</Form.Label>
                                    <Form.Control type="text" value={whitelistAddress} onChange={(e) => setWhitelistAddress(e.target.value)} />
                                </Form.Group>
                                <Button onClick={handleWhitelistVoter}>Whitelist Voter</Button>
                            </Form>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-col">
                        <Form>
                            <Form.Group controlId="checkCanVote">
                                <Form.Label>Vote Eligibility</Form.Label>
                                <Form.Control type="text" value={whitelistAddress} onChange={(e) => setWhitelistAddress(e.target.value)} />
                            </Form.Group>
                            <Button onClick={handleCheckCanVote}>Check Vote Eligibility</Button>
                        </Form>
                    </Col>
                </Row>
                 </>
                 )}

                {userRole === 'campaignCreator' && (
                    <>
                    <Row>
                        <Col className="col-col">
                            <Form>
                                <Form.Group controlId="candidateInput">
                                    <Form.Label>Enter candidate names separated by commas</Form.Label>
                                    <Form.Control type="text" value={props.candidateInput} onChange={props.handleCandidateInputChange} />
                                </Form.Group>
                                <Form.Group controlId="duration">
                                    <Form.Label>Duration in minutes</Form.Label>
                                    <Form.Control type="number" value={props.duration} onChange={props.handleDurationChange} />
                                </Form.Group>
                                <Button className="btn-primary" onClick={props.handleCreateCampaign}>Create Campaign</Button>
                                <Button onClick={handleAddCandidate}>Add Candidate</Button>
                            </Form>
                        </Col>
                    </Row>
                     <Row>
                     <Col className="col-col">
                         {props.showButton ? (
                             <p className="connected-account">You have already voted</p>
                         ) : (
                             <Form>
                                 <Form.Group controlId="candidateIndex">
                                     <Form.Label>Enter Candidate Index</Form.Label>
                                     <Form.Control type="number" placeholder="Enter Candidate Index" value={props.number} onChange={props.handleNumberChange} />
                                 </Form.Group>
                                 {isWhitelisted ? (
                                        <Button style={{textAlign:"center"}} className="login-button" onClick={handleVote}>Vote</Button>
                                    ) : (
                                        <Button style={{textAlign:"center"}} className="login-button" disabled onClick={() => toast.error('You are not whitelisted to vote')}>Vote</Button>
                                    )}
                             </Form>
                         )}
                     </Col>
                     <Col className="col-col">
                             <Form>
                                 <Form.Group controlId="whitelistVoter">
                                     <Form.Label>Whitelist Voter</Form.Label>
                                     <Form.Control type="text" value={whitelistAddress} onChange={(e) => setWhitelistAddress(e.target.value)} />
                                 </Form.Group>
                                <Button onClick={handleWhitelistVoter}>Whitelist Voter</Button>
                             </Form>
                     </Col>
                 </Row>
                 <Row>
                    <Col className="col-col">
                        <Form>
                            <Form.Group controlId="checkCanVote">
                                <Form.Label>Vote Eligibility</Form.Label>
                                <Form.Control type="text" value={whitelistAddress} onChange={(e) => setWhitelistAddress(e.target.value)} />
                            </Form.Group>
                            <Button onClick={handleCheckCanVote}>Check Vote Eligibility</Button>
                        </Form>
                    </Col>
                </Row>
                 </>
                )}

                {userRole === 'regularVoter' && (
                    <>
                     <Row>
                        <Col className="col-col">
                            {props.showButton ? (
                                <p className="connected-account">You have already voted</p>
                            ) : (
                                <Form>
                                    <Form.Group controlId="candidateIndex">
                                        <Form.Label>Enter Candidate Index</Form.Label>
                                        <Form.Control type="number" placeholder="Enter Candidate Index" value={props.number} onChange={props.handleNumberChange} />
                                    </Form.Group>
                                    {isWhitelisted ? (
                                        <Button style={{textAlign:"center"}} className="login-button" onClick={handleVote}>Vote</Button>
                                    ) : (
                                        <Button style={{textAlign:"center"}} className="login-button" disabled onClick={() => toast.error('You are not whitelisted to vote')}>Vote</Button>
                                    )}
                                </Form>
                            )}
                        </Col>
                        <Col className="col-col">
                            <Form>
                                <Form.Group controlId="checkCanVote">
                                    <Form.Label>Vote Eligibility</Form.Label>
                                    <Form.Control type="text" value={whitelistAddress} onChange={(e) => setWhitelistAddress(e.target.value)} />
                                </Form.Group>
                                <Button onClick={handleCheckCanVote}>Check Vote Eligibility</Button>
                            </Form>
                        </Col>
                    </Row>
                    </>

                )}
            </Container>

            <Row>
                <Col>
                    <div className="text-center">
                        <h3 style={{ color: "#FFFFFF" }}>Campaign Board</h3>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Index</th>
                                    <th>Candidate name</th>
                                    <th>Candidate votes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {candidates.map((candidate, index) => (
                                    <tr key={index}>
                                        <td>{candidate.index}</td>
                                        <td>{candidate.name}</td>
                                        <td>{candidate.voteCount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Connected;
