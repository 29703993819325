import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { contractAbi, contractAddress } from './Constant/constant';
import Login from './Components/Login';
import Finished from './Components/Finished';
import Connected from './Components/Connected';
import './App.css';
import { toast, ToastContainer } from 'react-toastify';


function App() {
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [votingStatus, setVotingStatus] = useState(true); // Start with false as no campaign is active
  const [remainingTime, setRemainingTime] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [number, setNumber] = useState('');
  const [candidateInput, setCandidateInput] = useState('');
  const [duration, setDuration] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [isCampaignCreator, setIsCampaignCreator] = useState(false);


  

  useEffect(() => {
    getCurrentStatus();
    getRemainingTime();
    isUserCampaignCreator();
    getCandidates()
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    }
  }, []);

  async function getCurrentStatus() {
    if (!provider || !account) return;

    try {
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(contractAddress, contractAbi, signer);
      const status = await contractInstance.getVotingStatus();
      setVotingStatus(status);
      if (status) {
        getRemainingTime();
        getCandidates();
      }
    } catch (error) {
      console.error('Error getting current status:', error);
    }
  }

  async function vote() {
    if (!provider || !account) return;

    try {
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(contractAddress, contractAbi, signer);

      const tx = await contractInstance.vote(number);
      await tx.wait();
      setVotingStatus(false); // Disable voting after successful vote
    } catch (error) {
      console.error('Error voting:', error);
    }
  }

  async function createCampaign() {
    if (!provider || !account) return;

    try {
        const signer = provider.getSigner();
        const contractInstance = new ethers.Contract(contractAddress, contractAbi, signer);
        
        // Split candidate names by commas
        const candidateNames = candidateInput.split(',');
        
        // Call createCampaign function on the contract
        const tx = await contractInstance.createCampaign(candidateNames, duration);
        await tx.wait();

         // Fetch updated candidate list from the contract after creating the campaign
         const updatedCandidatesList = await contractInstance.getAllVotesOfCandidates();
         const formattedCandidates = updatedCandidatesList.map((candidate, index) => {
             return {
                 index: index,
                 name: candidate.name,
                 voteCount: candidate.voteCount.toNumber()
             }
         });

         console.log(formattedCandidates);

        // Update the state of candidates with the updated list
        setCandidates(formattedCandidates);        
        getRemainingTime(); // Update remaining time

        setVotingStatus(true); // Start voting after campaign creation
        toast.success('Campaign created successfully');
    } catch (error) {
        console.error('Error creating campaign:', error);
        toast.error('Error creating campaign, try again');
    }
}

// async function createCampaign() {
//   if (!provider || !account) return;

//   try {
//       const signer = provider.getSigner();
//       const contractInstance = new ethers.Contract(contractAddress, contractAbi, signer);
      
//       // Split candidate names by commas
//       const candidateNames = candidateInput.split(',');
      
//       // Call createCampaign function on the contract, passing campaignName along with other parameters
//       const tx = await contractInstance.createCampaign(campaignName, candidateNames, duration);
//       await tx.wait();

//       // Fetch updated candidate list from the contract after creating the campaign
//       const updatedCandidatesList = await contractInstance.getAllVotesOfCandidates();
//       const formattedCandidates = updatedCandidatesList.map((candidate, index) => {
//           return {
//               index: index,
//               name: candidate.name,
//               voteCount: candidate.voteCount.toNumber()
//           }
//       });

//       // Update the state of candidates with the updated list
//       setCandidates(formattedCandidates);        
//       getRemainingTime(); // Update remaining time

//       setVotingStatus(true); // Start voting after campaign creation
//       toast.success('Campaign created successfully');
//   } catch (error) {
//       console.error('Error creating campaign:', error);
//       toast.error('Error creating campaign, try again');
//   }
// }



  async function getCandidates() {
    if (!provider || !account) return;

    try {
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(contractAddress, contractAbi, signer);
      const candidatesList = await contractInstance.getAllVotesOfCandidates();
      console.log("candidate list:", candidatesList);
      const formattedCandidates = candidatesList.map((candidate, index) => {
        return {
          index: index,
          name: candidate.name,
          voteCount: candidate.voteCount.toNumber()
        }
      });
      console.log(formattedCandidates)
      setCandidates(formattedCandidates);
    } catch (error) {
      console.error('Error getting candidates:', error);
    }
  }

  async function getRemainingTime() {
    if (!provider || !account) return;

    try {
      const signer = provider.getSigner();
      const contractInstance = new ethers.Contract(contractAddress, contractAbi, signer);
      const time = await contractInstance.getRemainingTime();
      setRemainingTime(parseInt(time, 16));
      console.log("This is the time",time);
    } catch (error) {
      console.error('Error getting remaining time:', error);
    }
  }

  async function handleAccountsChanged(accounts) {
    if (accounts.length > 0 && account !== accounts[0]) {
      setAccount(accounts[0]);
    } else {
      setIsConnected(false);
      setAccount(null);
    }
  }

  async function connectToMetamask() {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setAccount(address);
        console.log("Metamask Connected : " + address);
        setIsConnected(true);
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error("Metamask is not detected in the browser");
    }
  }

  function handleNumberChange(e) {
    setNumber(e.target.value);
  }

  function handleCreateCampaign() {
    createCampaign();
  }

  function handleCandidateInputChange(e) {
    setCandidateInput(e.target.value);
  }

  function handleDurationChange(e) {
    setDuration(e.target.value);
  }

  const isUserCampaignCreator = async () => {
    try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const contractInstance = new ethers.Contract (
            contractAddress, contractAbi, signer
        );
        const isCreator = await contractInstance.campaignCreatorWhitelist(await signer.getAddress());
        setIsCampaignCreator(isCreator);
    } catch (error) {
        console.error('Error checking if user is campaign creator:', error);
    }
};

  // function handleCampaignNameChange(e){
  //   setCampaignName(e.target.value);
  // }

  return (
    <div className="App">
      <ToastContainer />
      {votingStatus ? (
        isConnected ? (
          <Connected
            account={account}
            candidates={candidates}
            remainingTime={remainingTime}
            number={number}
            handleNumberChange={handleNumberChange}
            voteFunction={vote}
            showButton={!votingStatus}
            candidateInput={candidateInput}
            duration={duration}
            handleCandidateInputChange={handleCandidateInputChange}
            handleDurationChange={handleDurationChange}
            handleCreateCampaign={handleCreateCampaign}
            getCandidates={getCandidates} 
            getRemainingTime={getRemainingTime}
          />
        ) : (
          <Login connectWallet={connectToMetamask} />
        )
      ) : (
        <Finished />
      )}
    </div>
  );
}

export default App;
